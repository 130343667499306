import { UrlSegment, UrlMatchResult } from '@angular/router';

/**
 * Detect a dedicated agence page
 * @param url The full url
 * @returns null if no match, else the UrlMatcher
 */
export const agenceMatcher = (url: UrlSegment[]): UrlMatchResult => {
  return url.length > 0 && url[0].path.match(/^agence-immobiliere-[aA-zZ0-9-]+$/) ? {
    consumed: url.slice(0, 1),
    posParams: {
      slug: new UrlSegment(url[0].path, {})
    }
  } : null;
};

export const landingMatcher = (url: UrlSegment[]): UrlMatchResult => {
  return url.length > 0 && url[0].path.match(/^(toutes-nos-agences|agence-immobiliere|achat(?:[-a-z]*)?|location(?:[-a-z]*)?)$/i) ? {
    consumed: [],
    posParams: {
      type: new UrlSegment(url[0].path, {})
    }
  } : null;
};

/**
 * Eliminate the leading slash to generate a route path
 * @param route The full absolute route
 * @returns The extracted path
 */
export const routeToPath = (route: string): string => (
  route.startsWith('/') ? route.slice(1) : route
);
